import React from 'react';
import Layout from '../components/layouts';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { titleTags } from '../utilities/head-tags';
import GradientBlocks from '../components/shared/GradientBlocks';
import styled from 'styled-components';
import { breakpoints, Container } from '../styles/layout';
import { PurpleOutlineButtonLink } from '../components/buttons';
import { rtypeN21bu, rtypeN18r, rtypeT38sb } from '../styles/typography/presets';
import { colors } from '../styles/colors';
import FluidImg from '../components/shared/FluidImg';
import { linkResolver } from '../resolvers';
import { RichText } from 'prismic-reactjs';


const Title = styled.h1`
  ${rtypeN21bu};
  color: ${colors.orange};
`;

const Message = styled.div`
  ${rtypeT38sb};
`;

const Brief = styled.div`
  ${rtypeN18r};
  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  em {
    font-style: italic;
  }
`;

const Button = styled(PurpleOutlineButtonLink)`

`;

const TextBlock = styled.div`

`;

const Background = styled.div`
  // background-image: url(${props => props.image});
  // background-size: cover;
  // background-position: bottom right;
  // background-repeat: no-repeat;
  .gatsby-image-wrapper {
    height: 100%;
    img {
      object-fit: contain!important;
      object-position: center bottom!important;
    }
  }
`;

const CustomContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 60px;
  
  ${TextBlock} {
    grid-column: 2 / span 8;
    
    ${Title} {
      margin-bottom: 10px;
    }
    
    ${Message} {
      margin-bottom: 10px;
    }
    
    ${Button} {
      margin-top: 30px;
    }
  }
  
  ${Background} {
    display: none;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    margin-top: 100px;
    padding-top: 120px;
    padding-bottom: 0;
    grid-template-rows: auto 120px;
    
    ${TextBlock} {
      grid-row: 1 / span 1;
      grid-column: 7 / span 3;
      
      ${Title} {
        margin-bottom: 20px;
      }
      
      ${Message} {
        margin-bottom: 20px;
      }
      
      ${Button} {
        margin-top: 60px;
      }
    }
    
    ${Background} {
      display: block;
      grid-row: 1 / span 2;
      grid-column: 1 / span 8;
    }
  }
`;


class NotFoundPage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page('Not Found', {
      title: `Not Found | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const { data } = this.props;
    const pageData = data.prismicNotFoundPage.data;
    const rawData = JSON.parse(data.prismicNotFoundPage.dataString);

    return (
      <Layout>
        <Helmet>
          {titleTags(`${data.prismicSite.data.name}`)}
        </Helmet>
        <CustomContainer>
          <Background>
            <FluidImg image={pageData.image}/>
          </Background>
          <TextBlock>
            <Title>{pageData.title}</Title>
            <Message>{pageData.message}</Message>
            <Brief>{RichText.render(rawData.brief, linkResolver)}</Brief>
            <Button to={pageData.link}>{pageData.link_text}</Button>
          </TextBlock>
        </CustomContainer>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const query = graphql`
  query {
    prismicSite {
      data {
        name
      }
    }
    prismicNotFoundPage {
      dataString
      data {
        title
        message
        brief {
          html
          text
        }
        image {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        link
        link_text
      }
    }
  }
`;
